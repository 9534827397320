import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCircleExclamation, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faTicket } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faPlaneUp } from '@fortawesome/free-solid-svg-icons'
import { faSackDollar } from '@fortawesome/free-solid-svg-icons'
import { faHeart } from '@fortawesome/free-solid-svg-icons'


library.add(faSackDollar,faPlaneUp,faPhone,faBars,faFacebook,faTwitter,faInstagram,faCircleExclamation,faTicket,faArrowRight,faArrowLeft, faCircleXmark, faShoppingCart, faCheckCircle,faHeart)

createApp(App).use(store).use(router).use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 7,
    newestOnTop: true
}).component("font-awesome-icon", FontAwesomeIcon).mount('#app')
